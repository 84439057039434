import PreviewTemplate from "./pages/PreviewTemplate";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import MainApp from "./pages/MainApp";
import RenderTemplate from "./pages/RenderTemplate";
import PreviewMultiple from "./pages/PreviewMultiple";
import PreviewCertificate from "./pages/PreviewCertificate";
function App() {

  return (
    <Router>
      <Routes>
          <Route path="/"  element={<MainApp />} />
          <Route path="/preview/:design_id/:binding_data_id" element={<PreviewTemplate />} />
          <Route path="/previewcertificate/:design_id/:data_name" element={<PreviewCertificate />} />
          <Route path="/render/:design_id/:binding_data_id/action=:action&filetype=:filetype" element={<RenderTemplate />} />
          <Route path="/multiple-previews" element={<PreviewMultiple />} />
      </Routes>
    </Router>

  );
}

export default App;

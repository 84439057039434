import React from 'react'
import {Link}  from "react-router-dom"
import Navbar from '../components/Navbar'
function MainApp() {
    return (
        <div className="App" style={{overflow: "hidden"}}>
            <Navbar  />
            Welcome to Home page
            <br />
            <Link to='/multiple-previews'>
                Click here to view multiple previews
            </Link>
            <br />
            To render an image enter the following url
            <br />
            {`${process.env.REACT_APP_DNS_NAME}/render/DESIGN_ID/BINDIING_DATA_ID/action=ACTION&filetype=FILETYPE`}
            <br />
            ACTION= render/download
            <br />
            FILETYPE= pdf/png/jpg
            <br />
            To preview the html code enter the following url
            <br />
            {`${process.env.REACT_APP_DNS_NAME}/preview/DESIGN_ID/BINDIING_DATA_ID`}
        </div>
    )
}

export default MainApp

import axios from 'axios';
import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
function PreviewCertificate() {
    const { design_id, data_name } = useParams();
    const [previewCode, setpreviewCode] = useState();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/get_design`,{
            params: {
              id: design_id
            }
          })
        .then(response=>{
            if(response.status===200){
                setpreviewCode(response.data.html);
            }
        })
        .catch(err=>
            console.log(err));
    }, [design_id])
    return (
        <div> 
            <div id={`AE_Template${design_id}`}>
                <JsxParser  bindings={{
                    placeholder: {data_name: data_name}}}
                    jsx={previewCode} 
                />
            </div>
        </div>
    )
}

export default PreviewCertificate

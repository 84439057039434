import React,{useEffect} from 'react';
import { useParams } from 'react-router-dom';
import axios from "axios";
import FileDownLoad from "js-file-download";
import Navbar from '../components/Navbar';
function RenderTemplate() {
    const { design_id, binding_data_id, action, filetype } = useParams();
    useEffect(() => {

        if(action==="download"){
            axios({
                url: `${process.env.REACT_APP_BASE_URL}/api/render`,
                method: "GET",
                responseType: "blob",
                headers: {'Content-Type': 'image/png'},
                params: { 
                    design_id: design_id,
                    binding_data_id: binding_data_id,
                    action: action,
                    filetype: filetype
                  }
            })
            .then(response=>{
                console.log(response);
                let extension="";
                if(filetype==="png" || filetype==="jpg"){
                    extension= (filetype==="png") ? ".png" : ".jpg";
                }
                else{
                    extension=".pdf";
                }
                if(action==="download"){
                    FileDownLoad(response.data,"preview_template_"+design_id+"_"+binding_data_id+extension);
                }
            })
            .catch(err=>
                console.log(err));
        }
        
    }, [design_id,binding_data_id,action,filetype])
    return (
        <div>
            <Navbar />
            {action==="render" && <img src={`${process.env.REACT_APP_BASE_URL}/api/render?design_id=${design_id}&binding_data_id=${binding_data_id}&action=render&filetype=png`} alt="Rendered_Image" />}
        </div>
    )
}

export default RenderTemplate

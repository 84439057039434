import React,{useState} from 'react';
import Navbar from '../components/Navbar';
import Loader from "react-loader-spinner";
function PreviewMultiple() {
    const previews=[{
        design_id: "61c6d1d97737122949fdbf2e",
        binding_data_id: "61c5b211b2a04f0886afc99d"
    },
    {
        design_id: "61c6d1d97737122949fdbf2e",
        binding_data_id: "61c44ad7136c87af78fc4feb"
    },
    {
        design_id: "61c6d1d97737122949fdbf2e",
        binding_data_id: "61c6d2067737122949fdbf30"
    },
    {
        design_id: "61c5b168b2a04f0886afc99b",
        binding_data_id: "61c5b211b2a04f0886afc99d"
    },
    {
        design_id: "61c5b168b2a04f0886afc99b",
        binding_data_id: "61c44ad7136c87af78fc4feb"
    },
    {
        design_id: "61c5b168b2a04f0886afc99b",
        binding_data_id: "61c6d2067737122949fdbf30"
    },
    {
        design_id: "61c44bc1136c87af78fc4fed",
        binding_data_id: "61c5b211b2a04f0886afc99d"
    },
    {
        design_id: "61c44bc1136c87af78fc4fed",
        binding_data_id: "61c44ad7136c87af78fc4feb"
    },
    {
        design_id: "61c44bc1136c87af78fc4fed",
        binding_data_id: "61c6d2067737122949fdbf30"
    },
   
]
    const [loading, setLoading] = useState(new Array(previews.length).fill(true))
   
    const onLoad = (ind) => {
        const newLoading = [...loading]; 
        newLoading.splice(ind , 1, false)

        setLoading(newLoading);
      }
    return (
        <div > 
            <Navbar />
            <div className="m-2 text-center">
                <h1 class="text-3xl font-bold drop-shadow uppercase">Your Stories</h1>
            </div>
            <div className="flex justify-center">
                {
                    previews.map((preview,index)=>
                        <div className=" m-2">
                            <img className="rounded-lg" src={`${process.env.REACT_APP_BASE_URL}/api/render?design_id=${preview.design_id}&binding_data_id=${preview.binding_data_id}&action=render&filetype=png&cache=true`} alt={`preview${index}`} onLoad={()=>onLoad(index)}/>
                            {loading[index] 
                                &&     
                            <Loader
                                className="m-5"
                                type="Bars"
                                color="#2e363f"
                                height={100}
                                width={100}
                            />}
                         
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default PreviewMultiple

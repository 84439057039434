import axios from 'axios';
import React,{useEffect,useState} from 'react'
import { useParams } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
function PreviewTemplate() {
    const { design_id, binding_data_id } = useParams();
    const [previewCode, setpreviewCode] = useState();
    const [bindingData, setbindingData] = useState();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/api/preview`,{
            params: {
              design_id: design_id,
              binding_data_id: binding_data_id
            }
          })
        .then(response=>{
            setbindingData(response.data.data_bindings.data)
            setpreviewCode(response.data.design.html);
        })
        .catch(err=>
            console.log(err));
    }, [design_id,binding_data_id])

    /**
     * Function to generate QR Codes src for Printable Assets from event URL 
     * And appending the UTMs to the QR Link for tracking purpose.
     */
    const generateEventQR =(eventUrl, objectToAppend) => {
        var firstTime=true;
        for (const key in objectToAppend) {
            if(firstTime) {
                eventUrl+="?";
                firstTime=false;
            } else {
                eventUrl+="&";
            }
            eventUrl+=String(key) +"="+String(objectToAppend[key]);
        }
        var QRLink = "https://cdn4.allevents.in/qr-api/api/png/ae/?dl=1&text="+encodeURIComponent(eventUrl);
        return QRLink;
    }

    return (
        <div> 
            <div id={`AE_Template${design_id}`}>
                <JsxParser  bindings={{
                    placeholder: bindingData,
                    generateEventQR: generateEventQR}}
                    jsx={previewCode} 
                />
            </div>
        </div>
    )
}

export default PreviewTemplate

import React from 'react'
import aeLogo from '../images/ae-logo-white.png'
function Navbar() {
    return (
        <div>
            <nav className="flex items-center p-3 flex-wrap mb-2" style={{backgroundColor: "#2e363f"}}>
                <a href="/" className="p-2 mr-4 inline-flex items-center">
                    <img src={aeLogo} alt="ae-logo" width="200" height="48" />
                </a>
            </nav>
        </div>
    )
}

export default Navbar
